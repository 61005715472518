import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'hadsup-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnInit {
    @Input() isRequired: boolean = false;
    @Input() defaultText: string = 'Select item';
    @Input() labelName: string = 'Select item';
    @Input() items: any;
    @Input() value: any = '';
    @Input() isMultipleSelection = false;
    @Input() selectedItems: any[] = [];
    @Input() nameProp: any;
    @Input() valueProp: any;
    @Input() labelProp: any;
    @Input() displayValueProp = false;
    @Input() defaultValue: Number = -1;
    @Output() selectionChange = new EventEmitter<any>();
    @Input() hasRadio: boolean = false;
    dropdownActive: boolean = false;
    isDefaultSelection: any;

    ngOnInit(): void {
        document.addEventListener('click', this.closeDropdown.bind(this));
    }
    selectItem(item: any): void {
        if (!this.isMultipleSelection) {
            this.selectionChange.emit(item);
            this.value = item;
            item.isSelected = true;
        }
        this.dropdownActive = false;
    }
    onMultipleSelect(item: any, event: any): void {
        if (item) {
            if (item[this.valueProp] == this.defaultValue) {
                this.items.forEach((element: any) => {
                    element['isSelected'] = event.checked;
                });
            } else {
                item['isSelected'] = event.checked;
                this.items.find((f: any) => f[this.valueProp] == this.defaultValue)[
                    'isSelected'
                ] = true;
                this.items
                    .filter((f: any) => f[this.valueProp] != this.defaultValue)
                    .forEach((element: any) => {
                        if (!element['isSelected'])
                            this.items.find(
                                (f: any) => f[this.valueProp] == this.defaultValue
                            )['isSelected'] = false;
                    });
            }
            this.selectedItems = this.items.filter(
                (item: any) => item['isSelected'] === true
            );
            this.selectionChange.emit(this.selectedItems);
        }
        this.value =
            this.selectedItems?.length > 0 ? this.selectedItems?.[0]?.name : '';
        this.dropdownActive = false;
    }

    toggleDropdown() {
        // document.querySelectorAll('.dropdown-menu').forEach((element) => {
        //     element.classList.remove('show');
        // });

        this.dropdownActive = !this.dropdownActive;
    }

    closeDropdown(event: any): void {
        if (
            !event.target.closest('.dropdown-menu') &&
            !event.target.closest('.dropdown-toggle-click')
        ) {
            this.dropdownActive = false;
        }
    }
    resetSelection(): void {
        this.items.forEach((item: any) => (item.isSelected = false));  
        this.selectedItems = [];
        this.selectionChange.emit([]);  
      }
      
}
