import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { trigger, transition, style, animate } from '@angular/animations';
import { takeWhile } from 'rxjs/operators';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hadsup-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() type = 'success';
  @Input() imgSrc = '';
  @Input() message = '';
  @Input() subMessage = '';
  @Input() mainBtnContent = '';
  @Input() secondBtnContent = '';
  @Output() closeLayer: EventEmitter<void> = new EventEmitter<void>();
  public hide: any;
  public alive = true;
  private routerSubscription!: Subscription;
  constructor(private globalService: GlobalService, private router: Router) {
    this.hide = this.globalService.hideNotification$;
  }
  ngOnInit(): void {
    if (this.message === 'select-ad-spots') {
      this.globalService.selectedAdNum$
        .pipe(takeWhile(() => this.alive))
        .subscribe((res) => {
          this.message = `You selected ${res} ad spots`;
          if (!res) {
            this.close();
          }
        });
    }
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.close(); 
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
     if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  close() {
    this.closeLayer.emit();
  }

  mainBtnAction() {
    if (this.mainBtnContent === 'Preview & costs') {
      this.router.navigate(['/preview-costs']);
    }
    else {
      if (this.router.url.indexOf('single-ad-spot') > -1)
        this.router.navigate(['/advertiser/create-campaign']);
      this.globalService.setProceedNotificationClick(true);
    }
  }
}
