import { Injectable } from '@angular/core';
import { IToken } from '../interfaces/token.interface';
import { UserModel } from '../models/user.model';
import { IUser } from '../interfaces/user.interface';
import { IBooking, IDevice } from '../interfaces/device.interface';
import { ICampaign } from '../interfaces/campaign.interface';

@Injectable()
export class StorageService {

    public removeAll(){
      this.removeAccessToken()
      this.removeUserInfo()
      this.removeProfileInfo();
      this.removeCampaign();
      this.removeSelectedMultipleDateRange();
      this.removeSelectedDevices();
      localStorage.removeItem('SelectedDateRange');
      localStorage.removeItem('UploadedBannerFile');
      localStorage.removeItem('UploadedHologramFile');
    }

    public removeAllWithoutDevice(){
      this.removeAccessToken()
      this.removeUserInfo()
      this.removeProfileInfo();
      this.removeCampaign();
      localStorage.removeItem('UploadedBannerFile');
      localStorage.removeItem('UploadedHologramFile');
      this.removeCampaignReview()
    }

    public setClientAccessToken(value : IToken) {
        localStorage.setItem('ClientAccessToken', JSON.stringify(value));
      }

      public removeClientAccessToken() {
        localStorage.removeItem('ClientAccessToken');
      }

      public getClientAccessToken(): IToken {
        return JSON.parse(localStorage.getItem('ClientAccessToken')|| 'null');
      }

      public setAccessToken(value : IToken) {
        document.cookie = "isloggedin=true;path=/;domain=.hadsup.com";
        localStorage.setItem('AccessToken', JSON.stringify(value));
      }

      public removeAccessToken() {
        document.cookie = "isloggedin=false;path=/;domain=.hadsup.com";
        localStorage.removeItem('AccessToken');
      }

      public getAccessToken(): IToken {
        return JSON.parse(localStorage.getItem('AccessToken') || 'null');
      }

      public setUserInfo(value : UserModel) {
        localStorage.setItem('User', JSON.stringify(value));
      }

      public removeUserInfo() {
        localStorage.removeItem('User');
      }

      public getUserInfo(): UserModel {
        return JSON.parse(localStorage.getItem('User') || 'null');
      }

      public setProfileInfo(value : IUser) {
        localStorage.setItem('Profile', JSON.stringify(value));
      }

      public removeProfileInfo() {
        localStorage.removeItem('Profile');
      }

      public getProfileInfo(): IUser {
        return JSON.parse(localStorage.getItem('Profile') || 'null');
      }

      public removeCampaignDetails(){
        this.removeSelectedDevices();
        localStorage.removeItem('SelectedDateRange');
        localStorage.removeItem('UploadedBannerFile');
        localStorage.removeItem('UploadedHologramFile');
        localStorage.removeItem('RemovedBannerFile');
        localStorage.removeItem('RemovedHologramFile');
        localStorage.removeItem('CampaignTotalCost');
        localStorage.removeItem('Campaign')
        this.removeSelectedMultipleDateRange();
      }

      public setBookedDevices(data: IBooking[]){
        localStorage.setItem('BookedDevices',JSON.stringify(data));
      }

      public getBookedDevices(): IBooking[]{
        return JSON.parse(localStorage.getItem('BookedDevices') || 'null')
      }

      public removeBookedDevices(): any{
        return localStorage.removeItem('BookedDevices')
      }

      public setSelectedDevices(adSpots: IDevice[]){
        document.cookie = "deviceCount=" + (adSpots.filter( a => a.selected == true).length) + ";path=/; domain=hadsup.com";
        localStorage.setItem('SelectedDevices',JSON.stringify(adSpots));
      }

      public getSelectedDevices(): IDevice[]{
        return JSON.parse(localStorage.getItem('SelectedDevices') || 'null')
      }

      public removeSelectedDevices(): any{
        document.cookie = "deviceCount=0;path=/; domain=hadsup.com";
        return localStorage.removeItem('SelectedDevices')
      }

      public setSelectedDateRange(dateRange: any){
        localStorage.setItem('SelectedDateRange',JSON.stringify(dateRange));
      }

      public getSelectedDateRange(): any{
        return JSON.parse(localStorage.getItem('SelectedDateRange')|| 'null')
      }

      public removeSelectedDateRange(): any{
        return localStorage.removeItem('SelectedDateRange')
      }

      public setSelectedMultipleDateRange(dateRange: any){
        localStorage.setItem('SelectedMultipleDateRange',JSON.stringify(dateRange));
      }

      public getSelectedMultipleDateRange(): any{
        return JSON.parse(localStorage.getItem('SelectedMultipleDateRange')|| 'null')
      }

      public removeSelectedMultipleDateRange(): any{
        return localStorage.removeItem('SelectedMultipleDateRange')
      }


      public setUploadedBannerFile(file: any){
        localStorage.setItem('UploadedBannerFile',JSON.stringify(file));
      }

      public getUploadedBannerFile(): any{
        return JSON.parse(localStorage.getItem('UploadedBannerFile')|| 'null')
      }

      public removeUploadedBannerFile(): any{
        localStorage.removeItem('UploadedBannerFile')
      }

      public removeUploadedHologramFile(): any{
        localStorage.removeItem('UploadedHologramFile')
      }

      public setRemovedBannerFile(file: any){
        localStorage.setItem('RemovedBannerFile',JSON.stringify(file));
      }

      public getRemovedBannerFile(): any{
        return JSON.parse(localStorage.getItem('RemovedBannerFile')|| 'null')
      }

      public deleteRemovedBannerFile(): any{
        localStorage.removeItem('RemovedBannerFile')
      }



      public setCampaign(campaign: any){
        localStorage.setItem('Campaign',JSON.stringify(campaign));
      }

      public getCampaign(): ICampaign{
        return JSON.parse(localStorage.getItem('Campaign')|| 'null')
      }

      public removeCampaign(): any{
        localStorage.removeItem('Campaign')
      }
      public setCampaignReview(campaign: any){
        localStorage.setItem('CampaignReview',JSON.stringify(campaign));
      }

      public getCampaignReview(): ICampaign{
        return JSON.parse(localStorage.getItem('CampaignReview')|| 'null')
      }

      public removeCampaignReview(): any{
        localStorage.removeItem('CampaignReview')
      }
      public setIsReview(campaign: any){
        localStorage.setItem('IsReview',JSON.stringify(campaign));
      }

      public getIsReview(): ICampaign{
        return JSON.parse(localStorage.getItem('IsReview')|| 'null')
      }

      public removeIsReview(): any{
        localStorage.removeItem('IsReview')
      }

      public setSelectedLocation(value : any) {
        localStorage.setItem('SelectedLocation', JSON.stringify(value));
      }

      public removeSelectedLocation() {
        localStorage.removeItem('SelectedLocation');
      }

      public getSelectedLocation(): any {
        return JSON.parse(localStorage.getItem('SelectedLocation')|| 'null');
      }

      public setNorthEastSouthWest(value : any) {
        localStorage.setItem('NorthEastSouthWest', JSON.stringify(value));
      }

      public removeNorthEastSouthWest() {
        localStorage.removeItem('NorthEastSouthWest');
      }

      public getNorthEastSouthWest(): any {
        return JSON.parse(localStorage.getItem('NorthEastSouthWest')|| 'null');
      }


      public setAdSpotOpenedFromCampaign(value : boolean) {
        localStorage.setItem('AdSpotOpenedFromCampaign', JSON.stringify(value));
      }

      public removeAdSpotOpenedFromCampaign() {
        localStorage.removeItem('AdSpotOpenedFromCampaign');
      }

      public getAdSpotOpenedFromCampaign(): boolean {
        return JSON.parse(localStorage.getItem('AdSpotOpenedFromCampaign')|| 'null');
      }
   
      //adding dates and slots
      public setDatesAndSlots(data:any){
        localStorage.setItem('DatesAndSlots',JSON.stringify(data));
      }

      //get dates and slots
      public getDatesAndSlots(){
        return JSON.parse(localStorage.getItem('DatesAndSlots') || 'null');
      }
      public getUploadedHologramFile(): any{
        return JSON.parse(localStorage.getItem('UploadedHologramFile') || 'null')
      }

      public setRemovedHologramFile(file: any){
        localStorage.setItem('RemovedHologramFile',JSON.stringify(file));
      }

      public getRemovedHologramFile(): any{
        return JSON.parse(localStorage.getItem('RemovedHologramFile') ||'')
      }

      public deleteRemovedHologramFile(): any{
        localStorage.removeItem('RemovedHologramFile')
      }
}
