<div class="login-component-wrapper">
  <div class="login-content-wrap">
    <div class="form-group logo">
      <a [href]="salesPage"> <img src="/assets/images/hadsup-logo.svg"></a>
    </div>
    <h3>Recreate Password</h3>
    <p *ngIf="!success">Please enter your new password.</p>
    <form class="login-form-wrap" [formGroup]="resetPasswordForm" *ngIf="!success">
      <div class="hadsup-input">
        <span class="label">Enter New Password</span>
        <div class="wrapper" *ngIf="true">
          <input class="input" type="{{ newPasswordType }}" placeholder="Enter new password" formControlName="password"
            autocomplete="new-password" />
          <span class="eye-icon"><i class="uil {{ isNewPassword ? 'uil-eye-slash' : 'uil-eye' }} s-21"
              (click)="hideShow('NEW')"></i></span>
        </div>
        <div *ngIf="submitted && f['password'].errors" class="invalid-form">
          <div *ngIf="f['password'].errors['required']"> New Password is required </div>
          <div *ngIf="f['password'].errors['minlength']"> Password must be at least 8 characters </div>
        </div>
      </div>
      <div class="hadsup-input">
        <span class="label">Confirm Password</span>
        <div class="wrapper" *ngIf="true">
          <input class="input" type="{{ confirmPasswordType }}" placeholder="Re-enter password"
            formControlName="password_confirmation" autocomplete="off" />
          <span class="eye-icon"><i class="uil {{
                isConfirmPassword ? 'uil-eye-slash' : 'uil-eye'
              }} s-21" (click)="hideShow('CONFIRM')"></i></span>
        </div>
        <div *ngIf="submitted && f['password_confirmation'].errors" class="invalid-form">
          <div *ngIf="f['password_confirmation'].errors['required']"> Confirm Password is required </div>
          <div *ngIf="f['password_confirmation'].errors['mustMatch']"> Passwords must match </div>
        </div>
      </div>
      <div class="form-actions">
        <button class="action-btn" (click)="submit()" [disabled]="resetPasswordForm.invalid">
            <span *ngIf="!isLoading">Update</span>
            <div class="button-loading" *ngIf="isLoading">
                <span class="button-text">Loading...</span>
            </div>
        </button>
    </div>
    </form>
    <div class="p-4 w-100" *ngIf="success">
      <span>Your password has been reset. Try login again with the new password</span>
    </div>
  </div>
</div>