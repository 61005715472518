import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transactionType',
    pure: false,
})
export class TransactionTypePipe implements PipeTransform {
    transform(value: string): string {
        switch (value) {
            case 'Hold':
                return 'Hold';
            case 'TOP_UP':
                return 'Top Up';
            case 'CAMPAIGN_CHARGE':
                return 'Campaign Charge';
            case 'CASH_BACK':
                return 'Cash Back';
            case 'WITHDRAW':
                return 'Withdraw';
            case 'REFUND':
                  return 'Refund';
            case 'STRIPE_FEE':
                return 'Transaction Fee';
            case 'INVESTMENT':
                return 'Investment';
            default:
                return 'default';
        }
    }
}
