import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  rootUrl = `${environment.apiUrl}/api/v1/`;
  constructor(private http: HttpClient) { }

  GetProfile() {
    const MethodName: string = 'profile?expand=country,balance,currency,allBalances,clients';
    const url: string = this.rootUrl + MethodName;
    return this.http.get(url);
  }
  GetUserBalance(roleId: any) {
    const relativeUrl: string = 'user-balances?role_id=' + roleId;
    const url: string = this.rootUrl + relativeUrl;
    return this.http.get(url);
  }
  GetUserBalanceSalesAgent() {
    const relativeUrl: string = 'user-balances-affiliate-partner';
    const url: string = this.rootUrl + relativeUrl;
    return this.http.get(url);
  }
  AddRole(data: any) {
    const MethodName: string = 'profile/add-new-role';
    const url: string = this.rootUrl + MethodName;
    return this.http.put(url, data);
  }
  UpdatePassword(data: any) {
    const MethodName: string = 'profile/update-password';
    const url: string = this.rootUrl + MethodName;
    return this.http.put(url, data);
  }
}
