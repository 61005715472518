<div class="content-wrap">
  <div class="info-header-section">
    <div class="info-inner-wrap">
      <h1>Transactions</h1>
      <button *ngIf="transferFromList.length > 0" class="hadsup-btn hadsup-primary-default" data-toggle="modal"
        data-target="#transferModalStep-1">
        <span class=""> Transfer </span>
        <i class="uil uil-exchange"></i>
      </button>
      <button class="hadsup-btn hadsup-btn-default withdraw-btn" (click)="openWithdrawModal()"
        [ngClass]="{'show': isClassAdded}">
        <span class="withdraw-cons">Withdraw</span>
        <img src="../../../../assets/icons/withdraw.svg" alt="" />
      </button>
    </div>
    <p>Track, manage and forecast your Transactions.</p>
  </div>
  <div class="component-filters">
    <div *ngIf="loadingData || userBalanceloadingData" class="balance-box-wrap">
      <hadsup-shimmer-box></hadsup-shimmer-box>
      <hadsup-shimmer-box></hadsup-shimmer-box>
      <hadsup-shimmer-box></hadsup-shimmer-box>
      <hadsup-shimmer-box></hadsup-shimmer-box>
      <hadsup-shimmer-box></hadsup-shimmer-box>
    </div>
    <div *ngIf="!loadingData && !userBalanceloadingData && module !== 'advertiser'" class="balance-box-wrap">
      <div class="balance-box">
        <div class="title-wrap">
          <span class="icon-img">
            <img src="../../../../assets/icons/current-balance.svg" alt="" />
          </span>
          <p class="dashboard-value"> {{ balance || 0 | currency : currencySign }} </p>
        </div>
        <h4 class="title">Current Balance</h4>
      </div>
      <div class="balance-box">
        <div class="title-wrap">
          <span class="icon-img"><img src="../../../../assets/icons/pending-balance.svg" alt="" /></span>
          <p class="dashboard-value"> {{ pendingTransaction || 0 | currency : currencySign }} </p>
        </div>
        <h4 class="title">Pending Balance</h4>
      </div>
      <div class="balance-box">
        <div class="title-wrap">
          <span class="icon-img">
            <img src="../../../../assets/icons/last-withraw.svg" alt="" />
          </span>
          <p class="dashboard-value"> {{ userBalance.last_withdraw || 0 | currency : currencySign }} </p>
        </div>
        <!-- <h6>{{userBalance.last_withdraw_date | date : "dd/MM/YYYY"}}</h6> -->
        <h4 class="title">Last Withdraw</h4>
      </div>
      <div class="balance-box last-wrap">
        <div class="title-wrap">
          <span class="icon-img"><img src="../../../../assets/icons/withraw-form.svg" alt="" /></span>
          <p class="dashboard-value"> {{ currentBalance.withdraw || 0 | currency : currencySign }} </p>
        </div>
        <h4 class="title">Total Withdrawn Amount</h4>
      </div>
    </div>
    <div class="dashboard-section">
      <div class="dashboard-section">
        <div class="dashboard-date-table" [ngClass]="{ 'no-data': !transactions.length }">
          <table class="data-table">
            <thead *ngIf="transactions?.length">
              <tr>
                <th>
                  <div>Date</div>
                </th>
                <th>
                  <div>Type</div>
                </th>
                <th>
                  <div>Status</div>
                </th>
                <th>
                  <div>Advertiser name</div>
                </th>
                <th>
                  <div>Campaign name </div>
                </th>
                <th class="text-left">
                  <div class="amount-header">Amount</div>
                </th>
              </tr>
            </thead>
            <tbody *ngIf="loadingTransactionsData || profileLoadingData" class="shimmers-wrapper">
              <tr *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">
                <td *ngFor="let number of [0, 1, 2, 3,4,5]">
                  <div class="shimmer shimmer2y"></div>
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="!loadingTransactionsData && !profileLoadingData">
              <tr *ngFor="let transaction of transactions">
                <td>
                  <div> {{ transaction.created_at * 1000 | date : "dd/MM/yyyy" }} </div>
                </td>
                <td>
                  <div class="type-wrap"> {{ transaction.type | transactionType }} </div>
                </td>
                <td>
                  <div class="status-wrap" [ngClass]="transaction.status | transactionStatus">
                    <span>{{ transaction.status | transactionStatus }}</span>
                  </div>
                </td>
                <td>
                  <div> {{ transaction.relations.campaign?.user_name || '-'}} </div>
                </td>
                <td>
                  <div> {{transaction.relations.campaign?.name || '-' }} </div>
                </td>
                <td>
                  <div class="text-left">
                <td>
                  <div class="amount"> {{ transaction.amount | currency : currencySign }} </div>
                </td>
        </div>
        </td>
        </tr>
        </tbody>
        </table>
        <div *ngIf="pagingModel.from && pagingModel.total > 10" class="table-pagination">
          <div class="pagination-right">
            <button [disabled]="pagingModel.from === 1" class="pagination-btn" (click)="PrevPage()"> Previous </button>
            <button [disabled]="pagingModel.total === pagingModel.to" class="pagination-btn" (click)="NextPage()"> Next
            </button>
          </div>
          <div class="pagination-left">
            <div> {{ pagingModel.from }}-{{ pagingModel.to }}<span>of</span> {{ pagingModel.total }} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div id="modal-overlay" class="modal-overlay" [ngClass]="{'modal-overlay': isClassAdded}"
  (click)="closeModelOnOverlay($event)"></div>
<div class="modal withdraw-modal" [ngClass]="{'show': isClassAdded}">
  <div class="modal-content">
    <form [formGroup]="withdrawForm">
      <div class="modal-header">
        <h5 class="modal-title mb-0">Withdraw</h5>
        <button type="button" class="close" (click)="closeModel()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body withdraw-form">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'uk'}" (click)="onTabChange('uk')">UK Bank
              Account</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': activeTab === 'international'}"
              (click)="onTabChange('international')">International Bank Account</a>
          </li>
        </ul>
        <!-- UK Bank Account Form -->
        <div *ngIf="activeTab === 'uk'" class="tab-content">
          <div class="hadsup-input-wrap">
            <span class="label">Account holder name<span class="required-asterisk">*</span></span>
            <input class="input" type="text" placeholder="Enter account holder name"
              formControlName="account_holder_name" (keypress)="nameValidation($event)" />
            <div *ngIf="
              submitted && withdrawForm?.controls?.account_holder_name.errors
            ">
              <div *ngIf="withdrawFormInfo?.account_holder_name.errors?.required" class="error"> Enter account holder
                name </div>
            </div>
          </div>
          <div class="hadsup-input-wrap">
            <span class="label">Account number<span class="required-asterisk">*</span></span>
            <input class="input" type="text" placeholder="Enter account number" formControlName="account_number"
              id="account_number" (input)="formValidationReload()" (keypress)="numericOnly($event)" maxlength="20" />
            <div *ngIf="
            submitted && withdrawForm?.controls?.account_number.errors
          ">
              <div *ngIf="withdrawFormInfo?.account_number.errors?.required" class="error"> Enter account number</div>
            </div>
          </div>
          <div class="hadsup-input-wrap">
            <span class="label">Sort code<span class="required-asterisk">*</span></span>
            <input class="input" type="text" placeholder="Enter sort code" formControlName="sort_code" id="sort_code"
              (input)="formValidationReload()" />
            <div *ngIf="
            submitted && withdrawForm?.controls?.sort_code.errors
          ">
              <div *ngIf="withdrawFormInfo?.sort_code.errors?.required" class="error"> Enter sort code </div>
            </div>
          </div>
        </div>
        <!-- International Bank Account Form -->
        <div *ngIf="activeTab === 'international'" class="tab-content">
          <div class="hadsup-input-wrap">
            <span class="label">Account holder name<span class="required-asterisk">*</span></span>
            <input class="input" type="text" placeholder="Enter account holder name"
              formControlName="account_holder_name" (keypress)="nameValidation($event)" />
            <div *ngIf="submitted && withdrawForm?.controls?.account_holder_name.errors">
              <div *ngIf="withdrawFormInfo?.account_holder_name.errors?.required" class="error"> Enter account holder
                name </div>
            </div>
          </div>
          <div class="hadsup-input-wrap" *ngIf="activeTab === 'international'">
            <span class="label">IBAN<span class="required-asterisk">*</span></span>
            <input class="input" type="text" placeholder="Enter IBAN number" formControlName="iban_number"
              id="iban_number" (input)="formValidationReload()" />
            <div *ngIf="submitted && withdrawForm?.controls?.iban_number.errors">
              <div *ngIf="withdrawFormInfo?.iban_number.errors?.required" class="error"> Enter IBAN number </div>
            </div>
          </div>
          <div class="hadsup-input-wrap" *ngIf="activeTab === 'international'">
            <span class="label">SWIFT code<span class="required-asterisk">*</span></span>
            <input class="input" type="text" placeholder="Enter SWIFT code" formControlName="swift_code"
              (input)="formValidationReload()" />
            <div *ngIf="submitted && withdrawForm?.controls?.swift_code.errors">
              <div *ngIf="withdrawFormInfo?.swift_code.errors?.required" class="error"> Enter SWIFT code </div>
            </div>
          </div>
        </div>
        <div class="hadsup-input-wrap useramount">
          <span>Current Balance </span><span> {{ balance | currency : currencySign }}</span>
        </div>
        <div class="hadsup-input-wrap">
          <span class="label">Withdraw amount(£)<span class="required-asterisk">*</span></span>
          <input class="input" type="text" min="1" placeholder="Enter withdraw amount here" id="amount"
            formControlName="amount" (keypress)="isNumber($event)" (input)="inputChange($event)" />
          <div *ngIf="submitted && withdrawForm?.controls?.amount.errors">
            <span class="error"> {{ withdrawFormInfo?.amount.errors?.required ? "Enter withdraw amount" :
              withdrawFormInfo?.amount.errors?.invalidAmount }} </span>
          </div>
        </div>
      </div>
      <div class="modal-footer transferButton">
        <button (click)="clearForm()" type="button" class="hadsup-btn hadsup-primary-default"> Clear </button>
        <button type="button" class="hadsup-btn hadsup-primary-dark" data-toggle="modal" (click)="DoWithdraw()"> Request
          Withdraw </button>
      </div>
    </form>
  </div>
</div>