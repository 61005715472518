import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'hadsup-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  hadsuppage = `${environment.hadsup_home_url}`;
}
