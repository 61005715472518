import { Component } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'hadsup-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  isLoading: boolean = false;
  success: boolean = false;
  submitted: boolean = false;
  salesPage = `${environment.hadsup_home_url}sales`;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
  ) {
    this.forgotPasswordForm = fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
    });
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    this.submitted = true;

    if (this.forgotPasswordForm.valid) {
      const submittedForm = this.forgotPasswordForm.value;
      this.isLoading = true;
      this.authService
        .ForgotPassword(submittedForm)
        .subscribe(
          (response) => {
            this.success = response.success;
            this.isLoading = false;
            this.submitted = false;
          }
        )
        .add(() => {
          this.isLoading = false;
          this.submitted = false;
        });
    }
  }
  get f() {
    return this.forgotPasswordForm.controls;
  }
}
