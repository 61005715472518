<div class="form-container">
  <div class="form-card">
    <div class="back-icon" [routerLink]="['/login']"><i class="uil uil-angle-left s-24"></i><span>Back</span></div>
    <div class="form-group logo">
      <a [href]="salesPage"> <img src="/assets/images/hadsup-logo.svg"></a>
    </div>
    <h3>Password Reset Link</h3>
    <div *ngIf="!success">
      <p>Please enter your registered email to continue.</p>
    </div>
    <form [formGroup]="forgotPasswordForm" *ngIf="!success">
      <div class="form-group">
        <label for="email">Email</label>
        <input id="email" formControlName="email" type="email" placeholder="Enter your email" />
        <div *ngIf="submitted && f['email'].errors">
          <div class="error" *ngIf="f['email'].errors && f['email'].errors['required']"> The email field is required </div>
          <div class="error" *ngIf="f['email'].errors['pattern']"> Email must be a valid email address </div>
        </div>
      </div>
      <div class="form-actions">
        <button class="action-btn" (click)="submit()">
          <span *ngIf="!isLoading">Send Link</span>
          <div class="button-loading" *ngIf="isLoading">
            <span class="button-text">Loading...</span>
          </div>
        </button>
      </div>
    </form>
    <div class="alert-message" *ngIf="success"> Please check your email to reset the password. </div>
  </div>
</div>