<div class="content-wrap">
  <div class="chart-header">
      <div class="chart-title"> {{ title }} 
        <!-- <i class="uil uil-info-circle s-21" matTooltip="{{title}}"
        matTooltipPosition="right"></i> -->
    </div>
    <div class="graph-header">
      <div class="chart-legend" [id]="chartId + '-legend'"></div>
    </div>
  </div>
  <div #chartContainer [id]="chartId"></div>
</div>