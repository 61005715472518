import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'hadsup-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss',
})
export class NavBarComponent {
  @ViewChild('toggleButton') toggleButton: ElementRef | any;
  @ViewChild('menu') menu: ElementRef | any;
  isMenuOpen: boolean = false;
  isSettingsMenuOpen = false;
  constructor(private renderer: Renderer2, private storageService: StorageService, private router: Router, private elRef: ElementRef
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.isMenuOpen &&
        !this.elRef.nativeElement.contains(e.target) &&
        e.target !== this.toggleButton?.nativeElement
      ) {
        this.closeMenu();
      }
    });
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }
  toggleSettingsMenu(): void {
    this.isSettingsMenuOpen = !this.isSettingsMenuOpen;
  }

  logout(): void {
    this.storageService.removeAll();
    localStorage.clear();
    this.router.navigateByUrl('/login');
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  closeModelOnOverlay(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.closeMenu();
    }
  }
}
