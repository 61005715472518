<div class="content-wrap">
  <div class="mat-calendar-header">
    <div class="mat-calendar-controls">
      <div class="prev flex-center">
        <i class="uil uil-arrow-left" disabled="!previousEnabled()" (click)="previousClicked()"></i>
      </div>
      <div class="middle flex-center" (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
        [attr.aria-describedby]="['_buttonDescriptionId']" cdkAriaLive="polite">
        <span [attr.id]="['_buttonDescriptionId']" class="mr-1">{{ periodButtonText }}</span>
        <span *ngIf="calendar.currentView === 'month'"><i class="uil uil-angle-down s-16"></i></span>
        <span *ngIf="calendar.currentView !== 'month'"><i class="uil uil-angle-up s-16"></i></span>
      </div>
      <ng-content></ng-content>
      <div class="next flex-center">
        <i class="uil uil-arrow-right s-21" disabled="!nextEnabled()" (click)="nextClicked()"></i>
      </div>
    </div>
  </div>
</div>