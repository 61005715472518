import { Pipe, PipeTransform } from '@angular/core';
import { CampaignStatusEnum } from '../enums/campaign-status.enum';

@Pipe({
    name: 'campaignStatus',
    pure: false,
})
export class CampaignStatusPipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case CampaignStatusEnum.draft:
                return 'draft';
            case CampaignStatusEnum.onConfirmation:
                return 'review';
            case CampaignStatusEnum.canceled:
                return 'cancelled';
            case CampaignStatusEnum.scheduled:
                return 'scheduled';
            case CampaignStatusEnum.active:
                return 'active';
            case CampaignStatusEnum.archive:
                return 'archive';
            case CampaignStatusEnum.blocked:
                return 'blocked';
            case CampaignStatusEnum.completed:
                return 'completed';
            default:
                return '';
        }
    }
}
