import { Component } from '@angular/core';

@Component({
  selector: 'hadsup-shimmer-box',
  templateUrl: './shimmer-box.component.html',
  styleUrl: './shimmer-box.component.scss'
})
export class ShimmerBoxComponent {

}
