import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
  forwardRef,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ShimmerBoxComponent } from './components/shimmer-box/shimmer-box.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { provideHttpClient } from '@angular/common/http';
import { HighchartsChartModule } from 'highcharts-angular';
import { CustomCalendarHeaderComponent } from './components/custom-calendar-header/custom-calendar-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AdSpotChartComponent } from './components/ad-spot-chart/ad-spot-chart.component';
import { MatMenuModule } from '@angular/material/menu';
import { CampaignStatusPipe } from './pipes/campaign-status.pipe';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomBarGraphComponent } from './components/custom-bar-graph/custom-bar-graph.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { NotificationComponent } from './components/notification/notification.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { TransactionTypePipe } from './pipes/transaction-type.pipe';
import { TransactionStatusPipe } from './pipes/transaction-status.pipe';
import { ProfileComponent } from './components/profile/profile.component';
import { TimeFormatPipe } from './pipes/format.pipe';
import { CampaignTabAccessPipe } from './pipes/campaign-tab-access.pipe';
import { MatRadioModule } from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    ShimmerBoxComponent,
    FooterComponent,
    NavBarComponent,
    ForgotPasswordComponent,
    CustomCalendarHeaderComponent,
    AdSpotChartComponent,
    CampaignStatusPipe,
    ResetPasswordComponent,
    CustomBarGraphComponent,
    CustomSelectComponent,
    NotificationComponent,
    TransactionsComponent,
    TransactionTypePipe,
    TransactionStatusPipe,
    ProfileComponent,
    TimeFormatPipe,
    CampaignTabAccessPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HighchartsChartModule,
    MatDatepickerModule,
    MatButtonModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule
  ],
  exports: [
    FooterComponent,
    RegisterComponent,
    NavBarComponent,
    LoginComponent,
    ShimmerBoxComponent,
    AdSpotChartComponent,
    CampaignStatusPipe,
    ResetPasswordComponent,
    CustomBarGraphComponent,
    CustomCalendarHeaderComponent,
    CustomSelectComponent,
    FormsModule,
    HighchartsChartModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatMenuModule,
    AdSpotChartComponent,
    CampaignStatusPipe,
    ResetPasswordComponent,
    MatTooltipModule,
    CustomBarGraphComponent,
    TransactionsComponent,
    TransactionTypePipe,
    NotificationComponent,
    TransactionStatusPipe,
    MatCheckboxModule,
    ProfileComponent,
    TimeFormatPipe,
    CampaignTabAccessPipe,
    MatRadioModule
  ],
  providers: [provideHttpClient(), DatePipe, provideNativeDateAdapter(), {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSelectComponent),
    multi: true,
  }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule { }
