import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transactionStatus',
    pure: false,
})
export class TransactionStatusPipe implements PipeTransform {
    transform(value: number): string {
        switch (value) {
            case 1:
                return 'pending';
            case 30:
                return 'pending';
            case 50:
                return 'pending';
            case 31:
                return 'failed';
            case 51:
                return 'failed';
            case 32:
                  return 'completed';
            case 52:
                  return 'completed';
            default:
                return 'default';
        }
    }
}
