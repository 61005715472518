export enum UserTypeEnum {
    Sales = 17,
    House = 18,
    LocationOwner = 5,
    AdvertiserAgency = 6,
    AdvertiserBrand = 7,
    Investor = 8,
    Broker = 9,
    LocationAgent = 10,
    Sponsor = 11,
    SponsorAgent = 12,
    AdvertiserAgent = 13,
    Ab = 14,
    Bb = 15,
    Buffer = 16,
}

export enum VerificationStatus{
    Verified=1,
    Unverified=0,
    Rejected=2
}