import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';
@Pipe({
  name: 'campaignTabAccess',
  pure: false
})
export class CampaignTabAccessPipe implements PipeTransform {

  constructor(private storageService: StorageService) { }
  transform(currentCampaignState: any): any {
    let result = false;
    switch (currentCampaignState) {
      case 'AdVisual': {
        result = this.storageService.getCampaign() != undefined && this.storageService.getCampaign().devices?.length > 0;
        break;
      }
      case 'AdReview': {
        const result1 = this.storageService.getCampaign() != undefined && this.storageService.getCampaignReview();
        const result2 = this.storageService.getCampaign() != undefined && this.storageService.getCampaign().devices?.length > 0;
        result = result1 && result2;
        break;
      }
    }
    return result;
  }
}