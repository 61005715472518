import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  selectedAdNotificationOpened = false;
  selectedAdNumber: any = 0;
  public selectedAdNum$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  hideNotification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public proceedClickNotification$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public proceedClick$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public backClick$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public campaignDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public adSpotDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public adVisualDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public mediaActiveTab$: BehaviorSubject<any> = new BehaviorSubject<any>('EmtyMedia');
  get selectedAdNum() { return this.selectedAdNum$.value; }
  constructor() { }
  setSelectedAdNum() {
    this.selectedAdNum$.next(this.selectedAdNumber);
  }
  setHideNotification(status: boolean) {
    this.hideNotification$.next(status);
  }
  setProceedNotificationClick(status: boolean | any) {
    this.proceedClickNotification$.next(status);
  }
  setProceedClick(value: any) {
    this.proceedClick$.next(value);
  }
  getProceedClick() {
    return this.proceedClick$.asObservable();
  }
  setBackClick(value: any) {
    this.backClick$.next(value);
  }

  setCampaignDetails(value: any) {
    this.campaignDetails$.next(value);
  }

  checkDocumentFileExtension(fileExtension: any) {
    const _validFileExtensions = ['video/mp4'];
    const _imageValidFileExtensions = ['image/jpeg', 'image/jpg', 'image/png'];

    if (_validFileExtensions.indexOf(fileExtension.toString().toLowerCase()) > -1 || _imageValidFileExtensions.indexOf(fileExtension.toString().toLowerCase()) > -1) {
      return true;
    }
    return false;
  }
}