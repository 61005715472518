<div class="content-wrap">
  <div class="chart-container">
    <div class="graph-header">
      <div class="chart-title"> {{ title }} 
        <!-- <i class="uil uil-info-circle s-21" matTooltip="{{ title }}"
          matTooltipPosition="right"></i> -->
      </div>
      <div class="chart-legend">
        <ng-container *ngIf="legendItems && legendItems?.length">
          <div (mouseenter)="highlightBars('male')" (mouseleave)="resetBars()" class="legend-top"
            [ngClass]="{'age-groups': legendItems[0]?.name === 'Age Groups'}">
            <span class="legend-color" [ngStyle]="{ 'background-color': legendItems[0]?.color}"></span> {{
            legendItems[0]?.name }}
          </div>
          <div class="legend-top" (mouseenter)="highlightBars('female')" (mouseleave)="resetBars()"
            *ngIf="legendItems[0]?.name !== 'Age Groups'">
            <span class="legend-color" [ngStyle]="{ 'background-color': legendItems[1]?.color }"></span> {{
            legendItems[1]?.name }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="chart">
      <div class="vertical-text">{{ yAxisText }}</div>
      <div class="y-axis" [style.height.px]="gridContainer.offsetHeight">
        <div class="y-label" *ngFor="let item of yAxisValues; let i = index">{{ formatNumberWithSuffix(item) }}</div>
      </div>
      <div class="grid-container" #gridContainer>
        <div class="grid-line"></div>
        <div class="grid-line"></div>
        <div class="grid-line"></div>
        <div class="grid-line"></div>
        <div class="grid-line"></div>
        <div class="grid-line"></div>
      </div>
      <div class="bars-y-axis" *ngIf="barChartOptions || (!ageData && ageData?.length > 0)"
        [ngClass]="{ 'male-active': activeLegend === 'male', 'female-active': activeLegend === 'female' }">
        <div class="bar-group" *ngFor="let data of chartData" #gridContainer>
          <div class="bar-wrapper">
            <div class="bar male" [ngStyle]="{'height.%': getHeight(data.male, gridContainer.offsetHeight)}">
              <span class="label">{{ formatHighNumber(data.male) }}</span>
              <div class="tooltip hightChart-custom-tooltip">
                <div class="tooltip-title">Age Group: {{ data.ageGroup }}</div>
                <div class="tooltip-title">Male: {{ data.male }}</div>
              </div>
            </div>
            <div class="bar female" [ngStyle]="{
              'height.%': getHeight(data.female, gridContainer.offsetHeight) }">
              <span class="label">{{ formatHighNumber(data.female) }}</span>
              <div class="tooltip hightChart-custom-tooltip">
                <div class="tooltip-title">Age Group: {{ data.ageGroup }}</div>
                <div class="tooltip-title">Female: {{ data.female }}</div>
              </div>
            </div>
          </div>
          <div class="x-axis">{{ data.ageGroup }}</div>
        </div>
      </div>
      <div class="bars-y-axis" *ngIf="(ageData && ageData?.length > 0) || !chartData">
        <div class="bar-group" *ngFor="let data of ageData; let i = index">
          <div class="bar-wrapper">
            <div class="bar age-group" [ngStyle]="{'height.%': getHeight(data.value, gridContainer.offsetHeight)}">
              <span class="label">{{ formatHighNumber(data.value) }}</span>
              <div class="tooltip hightChart-custom-tooltip">
                <div class="tooltip-title">Age Group: {{ data.category }}</div>
                <div class="tooltip-title">Value: {{ data.value }}</div>
              </div>
            </div>
          </div>
          <div class="x-axis">{{ data.category }}</div>
        </div>
      </div>
    </div>
    <div class="chart-legend">
      <div>{{ barChartOptions?.title || seriesName }}</div>
    </div>
  </div>
</div>