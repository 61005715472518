import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUser } from '../../interfaces/user.interface';
import { ProfileService } from '../../services/profile.service';
import { StorageService } from '../../services/storage.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../validators/must-match.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'hadsup-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit, OnDestroy {
  public userObj: IUser = {} as IUser;
  private subscriptions = new Subscription();
  public loadingData = false;
  public changePasswordForm: FormGroup | any;
  public isNewPassword: boolean = false;
  public isConfirmPassword: boolean = false;
  public isOldPassword: boolean = false;
  public oldPasswordType = 'password';
  public newPasswordType = 'password';
  public confirmPasswordType = 'password';
  public submitted: boolean = false;
  public isLoading: boolean = false;
  constructor(
    private router: Router,
    private storageService: StorageService,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private toaster: ToastrService,
  ) {
    this.changePasswordForm = fb.group(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
        ]),
        confirm_password: new FormControl('', [Validators.required]),
        old_password: new FormControl('', [Validators.required]),
      },
      {
        validator: MustMatch('password', 'confirm_password'),
      }
    );
  }

  ngOnInit(): void {
    this.GetProfileDetails();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  GetProfileDetails() {
    this.loadingData = true;
    this.subscriptions.add(
      this.profileService.GetProfile().subscribe((response: any) => {
        if (response) {
          this.userObj = response.data;
          this.storageService.setProfileInfo(response.data);
        }
        this.loadingData = false;
      })
    );
  }
  logOut() {
    this.storageService.removeAll();
    this.storageService.removeAccessToken();
    this.router.navigate(['/login'])
  }
  passwordHideShow(type: string) {
    if (type === 'NEW') {
      this.isNewPassword = !this.isNewPassword;
      this.newPasswordType = this.isNewPassword ? 'text' : 'password';
    }
    if (type === 'OLD') {
      this.isOldPassword = !this.isOldPassword;
      this.oldPasswordType = this.isOldPassword ? 'text' : 'password';
    }
    if (type === 'CONFIRM') {
      this.isConfirmPassword = !this.isConfirmPassword;
      this.confirmPasswordType = this.isConfirmPassword
        ? 'text'
        : 'password';
    }
  }
  updatePassword() {
    if (this.isLoading) {
      this.submitted = false;
      return;
    }

    this.submitted = true;

    if (this.changePasswordForm.valid) {
      this.isLoading = true;
      const submittedForm = this.changePasswordForm.value;

      this.profileService.UpdatePassword(submittedForm)
        .subscribe({
          next: (response: any) => {
            if (response && response.success) {
              this.toaster.success('Password changed successfully', 'Success');
              this.submitted = false;
              this.isLoading = false;
              this.changePasswordForm.reset();
            }
          },
          error: (error: any) => {
            this.toaster.error(error.error.errors[0].errors, 'Error');
            this.submitted = false;
            this.isLoading = false;
          },
          complete: () => {
            this.submitted = false;
            this.isLoading = false;
          }
        });
    }
  }

  get f() {
    return this.changePasswordForm.controls;
  }
}

