<div [@slideInOut] class="notification-wrap" [class.hidden]="hide | async" [class.default]="type === 'default'"
    [class.success]="type === 'success'" [class.danger]="type === 'danger'" [class.info]="type === 'info'">
    <div class="img-circle" *ngIf="imgSrc">
        <img [src]="imgSrc" alt="">
    </div>
    <div class="message-contents d-flex flex-column justify-content-center" *ngIf="message || subMessage">
        <div class="msg"> {{message}} </div>
        <div class="sub-msg"> {{subMessage}} </div>
    </div>
    <div class="button-group d-flex align-items-center">
        <button class="second-btn" *ngIf="secondBtnContent" [class.danger-trans]="type === 'default'"
            [class.dark-trans-btn]="type !== 'default'"> {{secondBtnContent}} </button>
        <button class="main-btn" *ngIf="mainBtnContent" [class.primary-btn]="type === 'default'"
            [class.dark-btn]="type !== 'default'" (click)="mainBtnAction()"> {{mainBtnContent}} </button>
    </div>
</div>