import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegisterRequest, ForgotPasswordRequest, ResetPasswordRequest, LoginRequest } from '../models/auth.model';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  rootUrl = `${environment.apiUrl}/api/auth/`;
  tokenUrl = `${environment.apiUrl}/api/oauth/`;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  Token() {
    const MethodName: string = 'token';
    const url: string = this.tokenUrl + MethodName;
    return this.http.post<any>(url, { "client_id": 1, "client_secret": "XttrblgUW9q7kWSJhpkmv2XuAtftQa772VJgfKYs", "grant_type": "client_credentials", "scope": "*" });
  }

  RefreshToken(data: any) {
    const MethodName: string = 'refresh-token';
    const url: string = this.tokenUrl + MethodName;
    return this.http.post<any>(url, data);
  }
  Login(data: LoginRequest) {
    if (data) data['scopes'] = ['api'];
    const MethodName: string = 'login';
    const url: string = this.rootUrl + MethodName;
    return this.http.post<any>(url, data);
  }

  register(data: RegisterRequest): Observable<any> {
    if (data) data['scopes'] = ['api'];
    const url: string = `${this.rootUrl}register`;
    return this.http.post<any>(url, data);
  }
  ForgotPassword(data: ForgotPasswordRequest) {
    if (data) data['side'] = 'api';
    const MethodName: string = 'password/reset';
    const url: string = this.rootUrl + MethodName;
    return this.http.post<any>(url, data);
  }

  // ResetPassword(data: ResetPasswordRequest) {
  //   this.storageService.getClientAccessToken()
  //   const MethodName: string = 'password/token';
  //   const url: string = this.rootUrl + MethodName;
  //   return this.http.post<any>(url, data);
  // }
  ResetPassword(data: ResetPasswordRequest) {
    const token = this.storageService.getClientAccessToken().access_token;
    const MethodName: string = 'password/token';
    const url: string = this.rootUrl + MethodName;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
    return this.http.post<any>(url, data, { headers });
  }

}
