export enum CampaignStatusEnum {
    draft = 0,
    onConfirmation = 1,
    canceled = 2,
    scheduled = 3,
    active = 4,
    archive = 5,
    blocked = 6,
    completed = 7
}
