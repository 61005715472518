<div class="content-wrap">
  <div class="profile-component-wrapper">
    <div class="component-header">
      <div *ngIf="loadingData" class="component-header-left">
        <h5>loading</h5>
      </div>
      <div *ngIf="!loadingData" class="component-header-left">
        <h5>{{ userObj.user_name }}</h5>
      </div>
      <div class="component-header-right">
        <!-- <button
          class="hadsup-btn hadsup-primary-dark"
          [routerLink]="['edit-profile']"
        >
          Edit account
        </button> -->
        <button class="hadsup-btn hadsup-btn-default" (click)="logOut()"> Log out </button>
      </div>
    </div>
    <div class="profile-body">
      <div class="profile-info-wrapper">
        <div class="profile-info-row">
          <div class="info-col">
            <h5>Profile details</h5>
            <ul *ngIf="loadingData" class="list-unstyled loading-data">
              <li>
                <span class="shimmer shimmer2y"></span>
                <strong class="shimmer shimmer2y"></strong>
              </li>
              <li>
                <span class="shimmer shimmer2y"></span>
                <strong class="shimmer shimmer2y"></strong>
              </li>
            </ul>
            <ul *ngIf="!loadingData" class="list-unstyled">
              <li>
                <span>Email:</span><strong>{{ userObj.email }}</strong>
              </li>
              <li>
                <span>Name:</span><strong>{{ userObj.user_name }}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="profile-component-wrapper">
    <div class="component-header">
      <div class="component-header-left">
        <h5>Change password</h5>
      </div>
      <div class="component-header-right"></div>
    </div>
    <div class="profile-body password-update-form">
      <div class="profile-info-wrapper">
        <div class="profile-info-row">
          <div class="info-col">
            <div class="form-card">
              <form [formGroup]="changePasswordForm" class="changePasswordForm">
                <div class="form-group">
                  <label for="old_password">Old password </label>
                  <div class="wrapper">
                    <input class="input" placeholder="Enter old password" formControlName="old_password"
                      type="{{ oldPasswordType }}" />
                    <span class="eye-icon"><i class="uil {{
                          isOldPassword ? 'uil-eye-slash' : 'uil-eye'
                        }} s-21" (click)="passwordHideShow('OLD')"></i></span>
                  </div>
                  <div class="error-message">
                    <div *ngIf="submitted && f.old_password.errors" class="error">
                      <div *ngIf="f.old_password.errors"> The password field is required </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">New password</label>
                  <div class="wrapper">
                    <input class="input" placeholder="Enter new password" formControlName="password"
                      type="{{ newPasswordType }}" />
                    <span class="eye-icon"><i class="uil {{
                          isNewPassword ? 'uil-eye-slash' : 'uil-eye'
                        }} s-21" (click)="passwordHideShow('NEW')"></i></span>
                  </div>
                  <div class="error-message">
                    <div class="error" *ngIf="submitted && f.password.errors">
                      <div *ngIf="f.password.errors.required"> The new password field is required </div>
                      <div *ngIf="f.password.errors.minlength"> Password must be at least 8 characters </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="password">Confirm new password</label>
                  <div class="wrapper">
                    <input class="input" type="{{ confirmPasswordType }}" placeholder="Confirm new password"
                      formControlName="confirm_password" />
                    <span class="eye-icon"><i class="uil {{isConfirmPassword ? 'uil-eye-slash' : 'uil-eye'}} s-21"
                        (click)="passwordHideShow('CONFIRM')"></i></span>
                  </div>
                  <div class="error-message">
                    <div *ngIf="submitted && f['confirm_password'].errors">
                      <div class="error"
                        *ngIf="f['confirm_password'].errors && f['confirm_password'].errors['required']"> The confirm
                        Password field is required </div>
                      <div class="error" *ngIf="f.confirm_password.errors.mustMatch"> Passwords must match </div>
                    </div>
                  </div>
                </div>
                <div class="form-actions">
                  <button class="action-btn" (click)="updatePassword()">
                    <span *ngIf="!isLoading"> Change password</span>
                    <div class="button-loading" *ngIf="isLoading">
                      <span class="button-text">Loading...</span>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>