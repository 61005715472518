import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
@Component({
  selector: 'hadsup-custom-bar-graph',
  templateUrl: './custom-bar-graph.component.html',
  styleUrls: ['./custom-bar-graph.component.scss'],
})
export class CustomBarGraphComponent implements OnChanges, OnInit {
  @Input() chartId: string = '';
  @Input() title: string = '';
  @Input() barChartOptions: any = {};
  @Input() ageGroupChartOptions: any = {};
  @Input() yAxisText: any;
  @Input() legendItems: any = [];
  @Input() seriesName: any;
  public categories: string[] = [];
  public chartData: any[] = [];
  public ageData: any;
  public yAxisValues: number[] = [];
  public maxValue: number = 0;
  public activeLegend: string | null = null;
  minValue: number = 0

  ngOnInit() {
    this.renderChart();
    this.renderAgeGroupData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['barChartOptions'] && changes['barChartOptions'].currentValue) {
      this.renderChart();
    }
    if (changes['ageGroupChartOptions'] && changes['ageGroupChartOptions'].currentValue) {
      this.renderAgeGroupData();
    }
  }

  highlightBars(gender: string) {
    this.activeLegend = gender;
  }

  resetBars() {
    this.activeLegend = null;
  }

  renderChart() {
    const chartContainer = document.getElementById(this.chartId);
    if (!this.barChartOptions || !this.barChartOptions.data || !this.barChartOptions.categories) {
      return;
    }
    this.chartData = this.barChartOptions.data;
    this.categories = this.barChartOptions.categories;
    if (this.chartId === 'genderByPercentage') {
      this.maxValue = 100;
    } else {
      this.maxValue = Math.max(...this.chartData.flatMap((d: any) => [d.male, d.female].map((v: any) => parseFloat(v))));
    }
    this.generateYAxisValues();
    if (!chartContainer) return;
  }
  renderAgeGroupData() {
    const chartContainer = document.getElementById(this.chartId);
    if (!this.ageGroupChartOptions) {
      return
    }
    this.ageData = this.ageGroupChartOptions.categories?.map(
      (category: string, index: number) => ({
        category: category,
        value: this.ageGroupChartOptions?.data[index],
      })
    );
    this.categories = this.ageGroupChartOptions.categories;
    if (!this.ageData || this.ageData.length === 0) {
      return;
    }
    if (this.chartId === 'ageGroupByPercentage') {
      this.maxValue = 100;
    } else {
      this.maxValue = Math.max(...this.ageData.flatMap((d: any) => [d.value]?.map((v: any) => parseFloat(v))));
    }
    this.generateYAxisValues();
    if (!chartContainer) return;
  }

  getHeight(value: any, totalHeight: number): any {
    const numericValue: number = parseFloat(value);
    this.formatHighNumber(numericValue);
    const percentage = this.chartId.includes('Percentage') ? numericValue : (numericValue / this.yAxisValues[0]) * 100;
    return percentage;
  }


  generateYAxisValues() {
    if (this.chartId === 'genderByPercentage' || this.chartId === 'ageGroupByPercentage') {
      this.yAxisValues = [100, 80, 60, 40, 20, 0];
    } else {
      const offset = this.calculateOffset(this.maxValue);
      this.yAxisValues = [];
      const steps = Math.ceil(this.maxValue / offset);
      for (let i = steps; i >= 0; i--) {
        this.yAxisValues.push(i * offset);
      }
    }
  }


  calculateOffset(maxValue: any) {
    if (this.chartId === 'genderByPercentage' || this.chartId === 'ageGroupByPercentage') {
      return 10;
    }
    if (maxValue === 0) {
      return 20;
    }
    if (maxValue <= 100) {
      return 20;
    }
    const magnitude = Math.floor(Math.log10(maxValue));
    const base = Math.pow(10, magnitude - 1);
    const step = Math.ceil(maxValue / 5);
    const roundedStep = Math.ceil(step / base) * base;
    return roundedStep;
  }
  formatNumberWithSuffix(value: number): string {
    if (value >= 1_000_000_000) {
      return (value / 5_00_00_000).toFixed(0) + 'B';
    } else if (value >= 1_000_000) {
      return (value / 5_00_000).toFixed(0) + 'M';
    } else if (value >= 10_000) {
      return (value / 1_000).toFixed(0) + 'K';
    } else {
      return value.toString();
    }
  }
  formatHighNumber(value: number): string {
    if (value >= 1_000_000_000) {
      return (value / 1_000_000_000).toFixed(2) + 'B';
    } else if (value >= 1_000_000) {
      return (value / 5_00_000).toFixed(2) + 'M';
    } else if (value >= 10_000) {
      return (value / 1_000).toFixed(0) + 'K';
    } else {
      return value.toString();
    }
  }
}